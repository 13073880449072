import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Switch from '@material-ui/core/Switch';
import styled from 'styled-components';

export const ConsentDialog = styled(Dialog)`
  & .MuiDialogTitle-root {
    padding: 32px 24px 0;
  }

  & .MuiDialog-paper {
    background-color: #ffffff;
  }

  @media (min-width: 600px) {
    & .MuiDialogTitle-root {
      padding: 48px 56px 16px;
    }

    & .MuiDialogContent-root {
      padding: 16px 56px 16px;
    }
  }
`;

export const DialogHeading = styled.h2`
  color: rgb(215, 22, 235);
  font-size: 2rem;
  font-family: 'GT Super', serif;
  font-weight: 300;
  line-height: 1.6;
  margin: 0;
`;

export const DialogCopy = styled.p`
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  margin: 16px 0;
  color: #161710;
`;

export const Link = styled.a`
  color: rgb(215, 22, 235);

  &:hover,
  &:active,
  &:visited {
    color: rgb(215, 22, 235);
  }
`;

export const StackMobileActions = styled.div`
  @media (min-width: 600px) {
    padding: 16px 56px 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > :not(:first-child) {
      margin-left: 16px;
    }
  }

  @media (max-width: 599px) {
    padding: 16px 24px 32px;

    & > * {
      width: 100%;

      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
`;

const ButtonBase = styled(Button)`
  && {
    box-shadow: none;
    border-radius: 10px;
    padding: 9px 30px;

    .MuiButton-label {
      font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
      font-size: 14px;
      line-height: 21px;
    }

    &:hover,
    &:active {
      box-shadow: none;
    }
  }
`;

export const PrimaryButton = styled(ButtonBase)`
  && {
    color: rgb(255, 255, 255);
    border-color: #13144a;
    background-color: #13144a;

    .MuiButton-label {
      color: rgb(255, 255, 255);
    }

    &:hover,
    &:active {
      border-color: #13144a;
      background-color: #13144a;

      .MuiButton-label {
        color: rgb(255, 255, 255);
      }
    }
  }
`;

export const SecondaryButton = styled(ButtonBase)`
  && {
    color: #13144a;
    border: 2px solid #13144a;
    background-color: transparent;
    padding: 7px 28px;

    .MuiButton-label {
      color: #13144a;
    }

    &:hover,
    &:active {
      background-color: transparent;

      .MuiButton-label {
        color: #13144a;
      }
    }
  }
`;

export const SettingsContainer = styled.section`
  margin: 8px 0;
  @media (min-width: 365px) {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 8px;
    row-gap: 22px;
    align-items: start;
  }
`;

export const SettingHeading = styled.h1`
  font-size: 1.25rem;
  font-family: 'GT Super', serif;
  font-weight: 300;
  line-height: 1;
  color: #161710;
  margin: 0;
  padding-top: 10px;
`;

export const SettingDescription = styled.p`
  color: #161710;
  margin: 8px 0 0;
`;

export const PinkSwitch = styled(Switch)`
  && {
    .Mui-checked.Mui-disabled {
      color: rgb(242, 138, 253);
    }

    .Mui-checked {
      color: rgb(215, 22, 235);

      & + .MuiSwitch-track {
        background-color: rgb(215, 22, 235);
      }
    }
  }
`;
