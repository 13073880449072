import React, { useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Cookies, { CookieAttributes } from 'js-cookie';
import {
  ConsentDialog,
  DialogCopy,
  DialogHeading,
  Link,
  PinkSwitch,
  PrimaryButton,
  SecondaryButton,
  SettingDescription,
  SettingHeading,
  SettingsContainer,
  StackMobileActions,
} from './CookieConsent.styles';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConsentCookieNameV1 = 'cookieconsent_status';
const ConsentCookieNameV2 = 'cookieconsent_status_v2';
const PrivacyPolicyLink = 'https://tillitinvest.com/privacy';
const CookieDomain = '.tillitinvest.com';

export interface CookieConsentProps {
  /**
   * @deprecated Prefer onPreferencesChanged instead, and be prepared to handle
   * ad-hoc revocation or alteration of permission even after page load.
   */
  onAllowed?: () => void;
  /**
   * Callback to be invoked when the user either saves their cookie preferences
   * or, when the component loads and it finds that a stored set of preferences
   * are available.
   *
   * onPreferencesChanged may be called multiple times on a single page, as the
   * consent dialog could be re-opened and preferences amended (with permissions
   * expanded or reduced) at any time.
   */
  onPreferencesChanged?: (prefs: CookiePreferences) => void;
  /**
   * An optional list of paths upon which the consent dialog will not be shown if
   * no preferences are already set. Paths are compared case-sensitively to
   * location.pathname.
   */
  exceptOnPaths?: Array<string>;
}

export interface CookiePreferences {
  /**
   * If true, the user has accepted the use of performance and analytics cookies.
   */
  allowPerformanceCookies: boolean;
  /**
   * If true, the user has accepted the use of marketing and tracking cookies.
   */
  allowTrackingCookies: boolean;
}

export function CookieConsent({
  onAllowed,
  onPreferencesChanged,
  exceptOnPaths,
}: CookieConsentProps) {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState<'info' | 'settings'>('info');

  useEffect(() => {
    const savedPreferences = getSavedPreferences();

    if (
      savedPreferences === null &&
      !exceptOnPaths?.includes(location.pathname)
    ) {
      setOpen(true);
    }

    if (savedPreferences?.allowPerformanceCookies) {
      // Legacy support
      onAllowed?.();
    }

    if (savedPreferences) {
      onPreferencesChanged?.(savedPreferences);
    }
  }, []);

  const getSavedPreferences = () => {
    const v2Status = Cookies.get(ConsentCookieNameV2);

    if (v2Status) {
      return JSON.parse(v2Status) as CookiePreferences;
    }

    const v1Status = Cookies.get(ConsentCookieNameV1);
    if (v1Status === undefined) {
      return null;
    }

    return {
      allowPerformanceCookies: v1Status === 'allow',
      allowTrackingCookies: false,
    };
  };

  const setV1Cookie = (
    prefs: CookiePreferences,
    cookieOpts: CookieAttributes
  ) => {
    Cookies.set(
      ConsentCookieNameV1,
      prefs.allowPerformanceCookies ? 'allow' : 'deny',
      cookieOpts
    );
  };

  const handleSave = (prefs: CookiePreferences) => {
    const cookieOpts: CookieAttributes = {
      expires: 365,
      sameSite: 'Lax',
      secure: false,
      domain: CookieDomain,
    };

    var payload = JSON.stringify(prefs);

    // Legacy support so that consent expressed through this dialog still allows any code using older
    // versions of the popup to see the correct enabled state and not re-show the dialog
    setV1Cookie(prefs, cookieOpts);

    Cookies.set(ConsentCookieNameV2, payload, cookieOpts);

    // Legacy support for the onAllowed prop, which only really considered performance cookies
    // (so we'll only fire that callback if performance cookie have been opted-into).
    if (prefs.allowPerformanceCookies) {
      onAllowed?.();
    }

    onPreferencesChanged?.(prefs);

    setOpen(false);
  };

  let currentPage: React.ReactNode;
  switch (page) {
    case 'info':
      currentPage = (
        <CookieInfo
          onManageSettings={() => setPage('settings')}
          onAccept={() =>
            handleSave({
              allowPerformanceCookies: true,
              allowTrackingCookies: true,
            })
          }
        />
      );
      break;
    case 'settings':
      currentPage = (
        <CookieSettings onCancel={() => setPage('info')} onSave={handleSave} />
      );
      break;
  }

  return (
    <ConsentDialog
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="cookie-consent-dialog-title"
      maxWidth="sm"
      fullWidth
      scroll="body"
    >
      <DialogTitle id="cookie-consent-dialog-title" disableTypography>
        <DialogHeading>Cookie consent</DialogHeading>
      </DialogTitle>
      {currentPage}
    </ConsentDialog>
  );
}

interface CookieInfoProps {
  onManageSettings: () => void;
  onAccept: () => void;
}

function CookieInfo({ onManageSettings, onAccept }: CookieInfoProps) {
  return (
    <>
      <DialogContent>
        <DialogCopy>
          We use cookies to ensure you get the best experience while using
          TILLIT. You can find out more in our{' '}
          <Link href={PrivacyPolicyLink} target="_blank">
            Privacy policy
          </Link>
          .
        </DialogCopy>
      </DialogContent>
      <StackMobileActions>
        <SecondaryButton onClick={() => onManageSettings()}>
          Manage settings
        </SecondaryButton>
        <PrimaryButton onClick={() => onAccept()}>Accept</PrimaryButton>
      </StackMobileActions>
    </>
  );
}

interface CookieSettingsProps {
  onCancel: () => void;
  onSave: (preferences: CookiePreferences) => void;
}

function CookieSettings({ onCancel, onSave }: CookieSettingsProps) {
  const [allowPerformanceCookies, setAllowPerformanceCookies] = useState(false);
  const [allowTrackingCookies, setAllowTrackingCookies] = useState(false);

  return (
    <>
      <DialogContent>
        <SettingsContainer>
          <div>
            <SettingHeading>Essential cookies</SettingHeading>
            <SettingDescription>
              These cookies are essential to operate TILLIT, including letting
              you log in and use the platform. These cannot be changed.
            </SettingDescription>
          </div>
          <PinkSwitch checked={true} disabled />
          <div>
            <SettingHeading>Analytical and performance cookies</SettingHeading>
            <SettingDescription>
              These cookies help ensure that you get the best possible
              experience while using TILLIT. They allow us to recognise how
              visitors move around on TILLIT and help inform which features to
              develop to improve your experience.
            </SettingDescription>
          </div>
          <PinkSwitch
            checked={allowPerformanceCookies}
            onChange={(e) => setAllowPerformanceCookies(e.target.checked)}
          />
          <div>
            <SettingHeading>Marketing and tracking cookies</SettingHeading>
            <SettingDescription>
              These cookies allow TILLIT to monitor the effectiveness of our
              advertising campaigns and to customise advertisements for visitors
              to our website.
            </SettingDescription>
          </div>
          <PinkSwitch
            checked={allowTrackingCookies}
            onChange={(e) => setAllowTrackingCookies(e.target.checked)}
          />
        </SettingsContainer>
      </DialogContent>
      <StackMobileActions>
        <SecondaryButton onClick={() => onCancel()}>Cancel</SecondaryButton>
        <PrimaryButton
          onClick={() =>
            onSave({ allowPerformanceCookies, allowTrackingCookies })
          }
        >
          Save my preferences
        </PrimaryButton>
      </StackMobileActions>
    </>
  );
}
